/* For use in src/lib/core/theming/_palette.scss */
$blue-palette: (
  50 : #e4eefd,
  100 : #bbd5f9,
  200 : #8eb9f5,
  300 : #619df1,
  400 : #3f88ee,
  500 : #1d73eb,
  600 : #1a6be9,
  700 : #1560e5,
  800 : #1156e2,
  900 : #0a43dd,
  A100 : #ffffff,
  A200 : #d4ddff,
  A400 : #a1b6ff,
  A700 : #87a2ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


//$blue-palette: (
//  50 : #e8eaf6,
//  100 : #c5cae9,
//  200 : #9fa8da,
//  300 : #7986cb,
//  400 : #5c6bc0,
//  500 : #3f51b5,
//  600 : #3949ab,
//  700 : #303f9f,
//  800 : #283593,
//  900 : #1a237e,
//  A100 : #8c9eff,
//  A200 : #536dfe,
//  A400 : #3d5afe,
//  A700 : #304ffe,
//  contrast: (
//    50 : #000000,
//    100 : #000000,
//    200 : #000000,
//    300 : #000000,
//    400 : #000000,
//    500 : #000000,
//    600 : #000000,
//    700 : #000000,
//    800 : #000000,
//    900 : #000000,
//    A100 : #000000,
//    A200 : #000000,
//    A400 : #000000,
//    A700 : #000000,
//  )
//);
