@use "./theming /palette" as palette;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import '@angular/cdk/overlay-prebuilt.css';

.writi-root {
  // Include the common styles for Angular Material. We include this here so that you only
  // have to load a single css file for Angular Material in your app.
  // Be sure that you only ever include this mixin once!
  @include mat.core();
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$website-primary: mat.define-palette(palette.$blue-palette);

$website-accent: mat.define-palette(mat.$light-blue-palette, A200, A100, A400, A200);

// The warn palette is optional (defaults to red).
$website-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$light-theme: mat.define-light-theme((
  color: (
    primary: $website-primary,
    accent: $website-accent,
    warn: $website-warn,
  )
));

$dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $website-primary,
        accent: $website-accent,
        warn: $website-warn,
      ),
    )
);

/* You can add global styles to this file, and also import other style files */

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.writi-root {
  @include mat.all-component-themes($light-theme);
}

.writi-root .dark-mode {
  @include mat.all-component-colors($dark-theme);
}

.writi-root.popover-backdrop {
  background: rgba(0,0,0,.75)
}

//html, body { height: 100%; }
html.writi-root, .writi-root body, mat-sidenav-container, mat-sidenav-content, mat-sidenav {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.pm-5 mat-dialog-container {
  padding: 5px !important;
}
.writi-root {
  .no-padding {
    padding: 0!important;
  }
  .notice.b-light {
    Font-family: sans-serif;
  }

  .m-b-10.b-light {
    Font-family: sans-serif;
  }

  .compose-title {
    font-size: 14px;
    padding-left: 7px;
  }

  .m-b-10.b-light.ng-star-inserted {
    Font-family: sans-serif;
  }
 .no-padding .mat-dialog-container {
   padding: 0 !important;
 }
  .mask {
    position: fixed;
    background: red;
    opacity: 0.4;
    z-index: 1;
    pointer-events: none;
  }
  body {
    background: #fafafa;
  }
  .app-container {
    max-width: 870px;
    width: 100%;
    background: white;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .app-container-m {
    max-width: 1000px;
    width: 100%;
    background: white;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .app-title {
    font-family: 'Google Sans',Roboto,Arial,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75rem;
    hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
    color: #202124;
  }

  .app-secondary-title {
    letter-spacing: .01428571em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
    color: #5f6368;
  }
  .app-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .sidebar {
    max-width: 200px;
  }

  .ib {
    display: inline-block;
  }

  .no-input {
    border: none;
    &:focus {
      outline: none;
      border: none;
    }
  }

  .fr {
    display: flex !important;
    align-items: center;
    &.fc {
      justify-content: center;
    }
  }

  .fcol {
    display: flex;
    flex-direction: column;
  }

  .fh {
    height: 100%;
  }

  .fw {
    width: 100%;
  }
  .fh {
    height: 100%;
  }

  .n-p {
    padding: 0 !important;
  }
  .p-5 {
    padding: 5px;
  }
  .p-10 {
    padding: 10px !important;
  }

  .p-15 {
    padding: 15px !important;
  }

  .p-20 {
    padding: 20px;
  }

  .p-r-5 {
    padding-right: 5px;
  }

  .p-r-10 {
    padding-right: 10px;
  }

  .p-r-15 {
    padding-right: 15px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-15 {
    margin: 15px;
  }
  .m-r-10{
    margin-right: 10px !important;
  }
  .m-r-15{
    margin-right: 15px !important;
  }
  .m-r-20 {
    margin-right: 20px !important;
  }
  .m-b-10 {
    margin-bottom: 10px !important;
  }
  .m-b-5 {
    margin-bottom: 5px !important;
  }
  .m-b-20 {
    margin-bottom: 20px !important;
  }

  .m-t-5 {
    margin-top: 5px !important;
  }

  .m-t-10 {
    margin-top: 10px !important;
  }

  .m-t-20 {
    margin-top: 20px !important;
  }


  .b1 {
    border: solid 1px #80808045;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .b-light {
    font-weight: 100;
  }
  .rounded-border {
    border-radius: 8px;
    overflow: hidden;
  }

  .medium {
    max-width: 120px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .small {
    max-width: 100px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .tiny {
    max-width: 40px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .round {
    border-radius: 50%;
    overflow: hidden;
  }

  .text-center {
    text-align: center;
  }

  .sticky-header {
    position: sticky;
    top: 0;
  }

  .empty-message {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .toolbar-input-border {
    border-radius: 4px!important;
    border: 1px solid #dcdfe6!important;
    box-sizing: border-box!important
  }

  .toolbar-input {
    height: 36px;
    background-color: #fff;
    color: #606266;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
  }

  .pull-r {
    margin-left: auto !important;
  }
  .bg-p-reverse {
    background: white;
    color: #1e72eb;
  }

  .bg-white {
    background: white;
  }

  .bg-primary {
    background: #438de6;
    //background: #1d73eb;
    color: white;
  }

  * {
    box-sizing: border-box;
  }
}


.snack-success {
  background: #1e72eb;
  font-weight: 500;
  color: white;
}





//EDITOR
.img-resizer {
  background: red;
  width: 100px;
  z-index: 9999999999;
  position: relative;
}

.summary {
  background-color: rgba(243, 244, 245, .7);
  border-radius: 7px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: .25px;
  color: #707070;
  .important {
    font-weight: bold;
    text-decoration: underline;
    color: #88a8e8;
  }
  .title {
    font-weight: 300;
    line-height: 20px;
    font-style: italic;
    color: #686868;
  }
}

.underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}


.click-badge {
  position: absolute;
  top: -11px;
  right: -20px;
  border-radius: 50%;
  background: #0000ff3b;
  color: blue;
  padding: 2px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: none;
  transition: all 0.5s ease;
}

.badge-wrap:hover .click-badge {
  display: flex !important;
  transition: all 0.5s ease;
}








//sleek editor
.active-editor-button {
  background-color: rgba(32, 33, 36, 0.05) !important;
}
.app-btn {
  cursor: pointer;
  white-space: inherit;
  //width: 20px;
  display: flex;
  align-items: center;
  background: transparent;
  box-shadow: none;
  border: none;
}

.app-btn.circle-btn {
  border-radius: 50%;
  border: solid 1px;
  overflow: hidden;
}


.img-btn {
  padding: 0;
  img {
    width: 100%;
    max-width: 20px;
  }
}

.variable-node {
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
  border:solid 1px;
  font-size: 16px;
  border-radius: 4px;
  background: #e8f0fe;
  padding: 2px 5px;
  color: #1a73e8;
  margin: 0;
  white-space: nowrap;
  text-align: center;
}

.variable-node:disabled {
  background: rgb(0 0 0 / 6%) !important;
  color: grey;
}

.variable-block {
  text-decoration: none;
  box-sizing: border-box;
  border:solid 1px;
  font-size: 16px;
  border-radius: 4px;
  background: #e8f0fe;
  padding: 2px 5px;
  color: #1a73e8;
  display: flex;
  align-items: center;
  max-width: 300px;
  cursor: pointer;
  button {
    border: none;
    color: #1b72e8;
    background: transparent;
    margin-left: auto;
  }
}

.app-toolbar {
  min-height: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
  display: flex;
  padding: 10px;
  transition: opacity .3s ease-in-out;
  z-index: 50;
}

.NgxEditor__MenuBar {
  border: none !important;
}
.NgxEditor__Wrapper,
.toolbar-input-border {
  border-radius: 4px !important;
  //overflow: hidden;
  border: 1px solid #dcdfe6 !important;
  box-sizing: border-box !important;
}
.toolbar-col {
  padding-right: 10px;
}
.toolbar-input {
  height: 36px;
  background-color: #fff;
  color: #606266;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}

textarea.toolbar-input {
  height: auto !important;
}
.textarea-editor {
  border: none;
  resize: none;
  min-height: 140px;
  overflow: auto;
  outline: none !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.no-header {
  app-header {
    display: none;
  }
  app-root {
    height: 100%;
  }
}

.condition-block-modal .mat-dialog-container {
  padding: 0;
}
.NgxEditor {
  width: 100%;
}
//sleek editor


.current-comp .msg-add-btn,
.current-comp .msg-generate-btn {
  background: #438de6;
  color: #fff;
}

.addin-main {
  button {
    background: #438de6;
    color: #fff;
    border-radius: 0;
    margin: 5px;
  }
  button.outline {
    background: white !important;
    color: #1d73eb !important;
  }
}

.addin-snackbar {
  min-width: 300px !important;
  width: 100% !important;
  max-width: 100% !important;
  margin: 5px !important;
}

.addin-main app-user-profile-page  {
  .p-20 {
    padding: 0 !important;
  }
  .price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product {
    width: 100%;
  }
  .img-profile {
    display: none;
  }
  loading-button {
    font-size: 11px;
  }
}
.ink-non-selected-text-overlay {
  position: fixed;
  display: none;
  z-index: 9999999;
  background-color: rgba(228,92,150,0.09);
}

